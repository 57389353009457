<template>
  <div class="addTask">
    <div class="addTask-header">
      <span v-for="(item, index) in taskTypeList" :class="{activeClass : taskType === index}"
            @click="selectType(index)">{{ item }}</span>
    </div>
    <!--    普通任务-->
    <div class="form-box" v-show="taskType === 0 || taskType === 1">
      <el-form :model="ordinaryFrom" ref="ordinaryFrom" :rules="ordinaryRules">
        <el-form-item label="系统任务" v-if="taskType === 1">
          <el-cascader
              v-model="cascaderValue"
              :options="systemTaskList"
              :props="{value:'id',label:'name'}"
              @change="cascaderHandleChange"></el-cascader>
          <el-select v-model="cascaderValueTwo" placeholder="请选择" style="margin-left: 20px"
                     @change="cascaderHandleTwoChange">
            <el-option
                v-for="item in systemTaskListTwo"
                :key="item.task_id"
                :label="item.task_title"
                :value="item.task_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务标题" prop="title">
          <el-input
              type="text"
              show-word-limit
              v-model="ordinaryFrom.title"
              style="width:400px"
              maxlength="30"
              placeholder="请输入任务标题"
          ></el-input>
        </el-form-item>
        <el-form-item style="position: relative;" label="任务描述" prop="describe">
          <div id="article_content"></div>
        </el-form-item>
        <el-form-item label="上传封面" style="position: relative;" class="taskObject">
          <span class="imggeshi">358*206px</span>
          <div class="upload-img">
            <div class="upload-btn" onclick="imgInput.click()">
              <span class="addimg">+</span>
              <span>{{ coverSrc ? '重新上传' : '上传封面' }}</span>
            </div>
            <div class="cover-look">
              <img v-if="coverSrc" :src="coverSrc" alt="">
              <span v-if="coverSrc" @click="coverSrc = ''; ordinaryFrom.cover = ''">x</span>
            </div>
            <input
                ref="modelInputImg"
                type="file"
                id="imgInput"
                @change="uploadImg($event)"
                style="display:none"
                accept="image/jpeg,image/png,image/jpg"
            />
          </div>
        </el-form-item>
        <el-form-item label="类型分类" prop="taskclass">
          <el-select v-model="ordinaryFrom.taskclass">
            <el-option
                v-for="item in classIfl"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="截至日期" prop="time">
          <el-date-picker v-model="ordinaryFrom.time" type="datetime" placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="发布平台" prop="fabu">
          <el-select v-model="ordinaryFrom.fabu">
            <el-option
                v-for="item in platformList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务对象" style="position: relative;" class="taskObject">
          <el-button type="primary" class="blue-btn" @click="dialogFormVisible = true">选择班级</el-button>
          <div class="selectClassList">
              <span v-for="(item, index) in checkedCities">{{ selectForVisi(item) }}
            <i style="cursor: pointer" @click="deleCheckedCities(index)">x</i>
            </span>
          </div>
        </el-form-item>
        <el-form-item label="上传附件" prop="fil">
          <el-button size="small" type="primary" class="blue-btn" onclick="imgFile.click()">上传附件</el-button>
          <span style="color: #666;font-size: 12px;margin-left: 10px">支持上传文件类型为rar、zip</span>
          <input
              ref="modelInputFile"
              type="file"
              id="imgFile"
              @change="uploadFile($event)"
              style="display:none"
              accept=".rar,.zip"
          />
          <div class="file-look">
            <div class="look-item" v-for="item in ordinaryFrom.file">{{ item.file_name }} <span
                v-if="ordinaryFrom.file.length" @click="ordinaryFrom.file = []">x</span></div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <!--  创建实训任务  -->
    <div class="form-box" v-show="taskType === 2">
      <el-form :model="trainingFrom" ref="trainingFrom" :rules="trainingRules">
        <el-form-item label="系统项目" prop="project">
          <el-cascader
              v-model="trainingCascaderValue"
              :options="trainingFromClassList"
              @change="trainingFromClassChange"
              :props="{value:'id',label:'class_name', children:'children'}">
          </el-cascader>
          <el-select v-model="trainingFrom.project" style="margin-left: 20px">
            <el-option
                @click.native="trainingProjectChange(item)"
                v-for="item in trainingFromList"
                :key="item.id"
                :label="item.title"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="实训名称" prop="trainingName">
          <el-input
              type="text"
              show-word-limit
              v-model="trainingFrom.trainingName"
              style="width:400px"
              maxlength="30"
              placeholder="请输入实训名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="截至日期" prop="trainingTime">
          <el-date-picker v-model="trainingFrom.trainingTime" :picker-options="trainingOptions" type="datetime"
                          placeholder="选择日期时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="发布平台" prop="platform">
          <el-select v-model="trainingFrom.platform">
            <el-option
                v-for="item in platformList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型分类" prop="taskclass">
          <el-select v-model="trainingFrom.taskclass">
            <el-option
                v-for="item in classIfl"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务对象" style="position: relative;" class="taskObject">
          <el-button type="primary" class="blue-btn" @click="dialogFormVisible = true">选择班级</el-button>
          <div class="selectClassList">
              <span v-for="(item, index) in checkedCities">{{ selectForVisi(item) }}
            <i @click="deleCheckedCities(index)">x</i>
            </span>
          </div>
        </el-form-item>
        <el-form-item label="上传封面" style="position: relative;" class="taskObject">
          <span class="imggeshi">358*206px</span>
          <div class="upload-img">
            <div class="upload-btn" onclick="imgInput.click()">
              <span class="addimg">+</span>
              <span>{{ coverSrc ? '重新上传' : '上传封面' }}</span>
            </div>
            <div class="cover-look">
              <img v-if="coverSrc" :src="coverSrc" alt="">
              <span v-if="coverSrc" @click="coverSrc = ''; ordinaryFrom.cover = ''">x</span>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <!--  创建脚本任务  -->
    <div class="form-box" v-show="taskType === 3">
      <el-form :model="scriptFrom" ref="scriptFrom" :rules="scriptRules">
        <el-form-item label="选择脚本" prop="selectScript">
          <el-select v-model="scriptFrom.selectScript" style="width: 400px;">
            <el-option
                @click.native="scriptProjectChange(item)"
                v-for="item in scriptFromList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务标题" prop="taskName">
          <el-input
              type="text"
              show-word-limit
              v-model="scriptFrom.taskName"
              style="width:400px"
              maxlength="30"
              placeholder="请输入任务标题"
          ></el-input>
        </el-form-item>
        <!--        <el-form-item label="脚本标题" prop="scriptName">-->
        <!--          <el-input-->
        <!--              type="text"-->
        <!--              show-word-limit-->
        <!--              v-model="scriptFrom.scriptName"-->
        <!--              style="width:400px"-->
        <!--              maxlength="30"-->
        <!--              placeholder="请输入脚本标题"-->
        <!--          ></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label="脚本简介" prop="scriptIntro">
          <el-input
              type="textarea"
              show-word-limit
              :rows="4"
              v-model="scriptFrom.scriptIntro"
              style="width:400px"
              maxlength="150"
              placeholder="请输入脚本简介"
          ></el-input>
        </el-form-item>
        <el-form-item label="截至日期" prop="scriptTime">
          <el-date-picker v-model="scriptFrom.scriptTime" :picker-options="scriptOptions" type="datetime"
                          placeholder="选择截止日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="发布平台" prop="scriptPlatform">
          <el-select v-model="scriptFrom.scriptPlatform">
            <el-option
                v-for="item in platformList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型分类" prop="taskclass">
          <el-select v-model="scriptFrom.taskclass">
            <el-option
                v-for="item in classIfl"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务对象" style="position: relative;" class="taskObject">
          <el-button type="primary" class="blue-btn" @click="dialogFormVisible = true">选择班级</el-button>
          <div class="selectClassList">
              <span v-for="(item, index) in checkedCities">{{ selectForVisi(item) }}
            <i @click="deleCheckedCities(index)">x</i>
            </span>
          </div>
        </el-form-item>
        <el-form-item label="上传封面" style="position: relative;" class="taskObject">
          <span class="imggeshi">358*206px</span>
          <div class="upload-img">
            <div class="upload-btn" onclick="imgInput.click()">
              <span class="addimg">+</span>
              <span>{{ coverSrc ? '重新上传' : '上传封面' }}</span>
            </div>
            <div class="cover-look">
              <img v-if="coverSrc" :src="coverSrc" alt="">
              <span v-if="coverSrc" @click="coverSrc = ''; ordinaryFrom.cover = ''">x</span>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog title="选择班级" :visible.sync="dialogFormVisible" style="z-index: 99999!important;">
      <el-checkbox-group
          ref="selectCheck"
          size="small"
          v-model="checkedCitiesCopy"
          @change="selectClassChange"
      >
        <el-checkbox v-for="(city,index) in cities" :checked="city.checked" :label="city.id" :key="city.id">
          {{ city.name }}
        </el-checkbox>
      </el-checkbox-group>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleFalse">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisi">确 定</el-button>
      </div>
    </el-dialog>
    <div class="announce">
      <el-button class="announce-btn" type="primary" @click="releaseBtn">发布任务</el-button>
      <el-button class="announce-btn" @click="cancelBtn">取消</el-button>
    </div>
  </div>
</template>

<script>
import WE from "./../../utils/wEditor";
import {getFormatDate} from "@/utils/formatDate";

export default {
  name: "addTask",
  data() {
    return {
      taskTypeList: ['普通任务', '系统任务', '实训任务', '脚本任务'],
      taskType: 0,
      //普通任务
      ordinaryFrom: {
        id: '',
        title: '',
        describe: '',
        cover: '',
        taskclass: '',
        time: '',
        fabu: '',
        classGrable: "", //班级
        file: [] //附件
      },
      coverSrc: '',
      ordinaryRules: {
        title: [
          {required: true, message: "请输入任务标题", trigger: "blur"},
          {min: 3, max: 30, message: "长度在 3 到 30 个字符", trigger: "blur"}
        ],
        describe: [
          {required: true, message: "请输入任务描述", trigger: "blur"},
        ],
        taskclass: [{required: true, message: "请选择类型分类", trigger: "change"}],
        time: [{type: "date", required: true, message: "请选择截至时间", trigger: "change"}],
        fabu: [{required: true, message: "请选择发布平台", trigger: "change"}],
        classGrable: [{required: true, message: "请选择任务对象", trigger: "change"}],
      },
      classIfl: [], //分类列表
      platformList: [], //平台列表
      dialogFormVisible: false,
      checkedCities: [], //选择班级列表
      checkedCitiesCopy: [], //选择班级列表
      cities: [], //班级数量列表
      cascaderValue: [], //系统任务选择器
      cascaderValueTwo: [],
      systemTaskList: [],
      systemTaskListTwo: [],
      editorText: null,
      //实训任务
      trainingFrom: {
        id: '', //实训id
        task_id:'', //编辑任务id
        project: '',
        trainingName: '',
        trainingTime: '',
        platform: '',
        classGrable: '',
        taskclass:'',
      },
      trainingFromClassList: [],
      trainingCascaderValue: [],
      trainingRules: {
        project: [{required: true, message: "请选择实训项目", trigger: "change"}],
        trainingName: [
          {required: true, message: "请输入实训名称", trigger: "blur"},
          {min: 3, max: 30, message: "长度在 3 到 30 个字符", trigger: "blur"}
        ],
        trainingTime: [
          {type: 'date', required: true, message: '请选择截至日期', trigger: 'change'}
        ],
        platform: [
          {required: true, message: "请选择发布平台", trigger: "change"}
        ],
        taskclass: [
          {required: true, message: "请选择类型分类", trigger: "change"}
        ]
      },
      trainingFromList: [],
      trainingOptions: {
        disabledDate(time) {
          return time.getTime() <= (Date.now() - (24 * 60 * 60 * 1000))
        }
      },
      //脚本任务
      scriptFrom: {
        id: '', //脚本id
        task_id:'', //编辑任务id
        taskName: '',
        selectScript: '',
        scriptName: '',
        scriptIntro: '',
        scriptTime: '',
        scriptPlatform: '',
        taskclass:'',
      },
      scriptRules: {
        taskName: [
          {required: true, message: "请输入任务标题", trigger: "blur"},
          {min: 3, max: 30, message: "长度在 3 到 30 个字符", trigger: "blur"}
        ],
        selectScript: [
          {required: true, message: "请选择脚本", trigger: "change"}
        ],
        scriptName: [
          {required: true, message: "请输入脚本标题", trigger: "blur"},
          {min: 3, max: 30, message: "长度在 3 到 30 个字符", trigger: "blur"}
        ],
        scriptIntro: [
          {required: true, message: "请输入脚本简介", trigger: "blur"},
          {min: 3, max: 150, message: "长度在 3 到 150 个字符", trigger: "blur"}
        ],
        scriptTime: [
          {type: 'date', required: true, message: '请选择截至日期', trigger: 'change'}
        ],
        scriptClassGrable: [
          {type: 'array', required: true, message: '请选择班级', trigger: 'change'}
        ],
        scriptPlatform: [
          {required: true, message: "请选择发布平台", trigger: "change"}
        ],
        taskclass: [
          {required: true, message: "请选择类型分类", trigger: "change"}
        ]
      },
      scriptFromList: [],
      scriptOptions: {
        disabledDate(time) {
          return time.getTime() <= (Date.now() - (24 * 60 * 60 * 1000))
        }
      },
    }
  },
  mounted() {
    this.editorText = new WE('#article_content', this.$api.artUploadImg, ['head', 'bold', 'quote', 'underline', 'code', 'link', 'image', 'video']);
    this.getClassify();
    this.getPlatform();
    this.getClassCount();
  },
  methods: {
    selectClassChange(val) {
    },
    // 选择创建任务类型
    selectType(index) {
      if(this.taskType === 0 || this.taskType === 1){
        this.$refs.ordinaryFrom.resetFields();
      } else if(this.taskType === 2){
        this.$refs.trainingFrom.resetFields();
      } else {
        this.$refs.scriptFrom.resetFields();
      }
      this.emptyForm()
      this.taskType = index;
      if (this.taskType === 1) {
        this.getSystemTask();
      } else if (this.taskType === 2) {
        this.getTrainClassList()
      } else if (this.taskType === 3) {
        this.getScriptList();
      }
    },
    // 获取分类列表
    getClassify() {
      let params = {};
      this.$httpteacher.axiosGetBy(this.$api.classification, params, (res) => {
        if (res.code === 200) {
          this.classIfl = res.data.data;
        }
      })
    },
    // 获取平台列表
    getPlatform() {
      let params = {};
      this.$httpteacher.axiosGetBy(this.$api.platformList, params, res => {
        this.platformList = res;
      });
    },
    //获取班级数量
    getClassCount() {
      let parms = {};
      this.$httpteacher.axiosGetBy(this.$api.classList, parms, (res) => {
        if (res.code === 200) {
          this.cities = res.list
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    // 删除所选班级
    deleCheckedCities(index) {
      this.checkedCities.splice(index, 1);
      let str = '';
      this.checkedCities.map(item => {
        str += `${item},`
      })
      if (str.length > 0) {
        str = str.substr(0, str.length - 1)
      }
      this.ordinaryFrom.classGrable = str;
    },
    dialogFormVisibleFalse() {
      this.checkedCitiesCopy = this.checkedCities
      this.dialogFormVisible = false;
    },
    // 确定班级
    dialogFormVisi() {
      let str = '';
      this.checkedCities = this.checkedCitiesCopy
      this.checkedCities.map(item => {
        str += `${item},`
      })
      if (str.length > 0) {
        str = str.substr(0, str.length - 1)
      }
      this.ordinaryFrom.classGrable = str;
      this.dialogFormVisible = false;
    },
    selectForVisi(id) {
      if (this.cities.length) {
        if (this.checkedCities.length) {
          for (let i = 0; i < this.cities.length; i++) {
            if (this.cities[i].id === id) {
              return this.cities[i].name
            }
          }
        }
      }
    },
    // 上传图片 三种类型公用  存的地方也一样
    uploadImg(event) {
      // let imgName = event.target.files[0].name;
      const _this = this;
      let formData = new FormData();
      let files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        let fileType = ['png', 'jpeg', 'jpg'];
        if (!fileType.includes(files[i].name.split('.')[files[i].name.split('.').length - 1].toLowerCase())) {
          _this.$message.warning('图片格式错误！');
          return;
        }
      }
      let _file = event.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(_file)
      reader.onload = function (e) {
        let image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          let w = image.width;
          let h = image.height;
          if (w !== 358 || h !== 206) {
            _this.$message.warning('请上传尺寸为358px*206px的图片');
            _this.$refs.modelInputImg.value = null;
          } else {
            formData.append('file', files[0])
            _this.$httpteacher.axiosPost(_this.$api.uploadImg, formData, res => {
                  if (res.code == 200) {
                    _this.coverSrc = res.data.url;
                    _this.ordinaryFrom.cover = res.data.src;
                    _this.$refs.modelInputImg.value = null;
                  } else {
                    _this.coverSrc = ''
                    _this.ordinaryFrom.cover = '';
                    _this.$message.warning(res.message);
                    _this.$refs.modelInputImg.value = null;
                  }
                },
                err => {
                  // console.log(222, err)
                }
            );
          }
        }
      }
    },
    // 脚本上传
    scriptUploadFile(event) {

    },
    // 上传附件
    uploadFile(event) {
      // let imgName = event.target.files[0].name;
      let formData = new FormData();
      let files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        let fileType = ['rar', 'zip'];
        // console.log(582,files[i].name.split('.')[files[i].name.split('.').length - 1].toLowerCase())
        if (!fileType.includes(files[i].name.split('.')[files[i].name.split('.').length - 1].toLowerCase())) {
          this.$message.warning('文件格式错误！');
          return;
        }
      }
      formData.append("file", files[0]);
      this.$httpteacher.axiosPost(this.$api.uploadFile, formData, res => {
            if (res.code === 200) {
              this.ordinaryFrom.file = [];
              let obj = {
                file_name: files[0].name,
                file_path: res.url
              }
              this.ordinaryFrom.file.push(obj);
              this.$message.success(res.msg)
              this.$refs.modelInputFile.value = null;
            } else {
              this.$message.warning(res.msg)
            }
          },
          err => {
            // console.log(err);
          }
      );
    },
    // 获取系统任务分类
    getSystemTask() {
      this.$httpteacher.axiosGet(this.$api.classification, (res) => {
        if (res.code == 200) {
          this.systemTaskList = res.data.data
        }
      })
    },
    // 系统任务次选择器
    cascaderHandleChange(value) {
      this.cascaderValueTwo = '';
      // return
      let id = value[value.length - 1];
      let param = {
        class_id: id,
        type: 0
      }
      this.$httpteacher.axiosGetBy(this.$api.systemTaskList, param, (res) => {
        if (res.code == 200) {
          this.systemTaskListTwo = res.list;
        }
      }, (err) => {
        // console.log(err);
        this.$message('暂无任务')
      })
    },
    cascaderHandleTwoChange(value) {
      this.$httpteacher.axiosGetBy(this.$api.systemTaskDetail, {id: value}, (res) => {
        if (res.code === 200) {
          this.ordinaryFrom.title = res.info.task_title;
          if (res.info.task_class_id) {
            this.ordinaryFrom.taskclass = res.info.task_class_id;
          }
          this.editorText.setWEHtml(res.info.task_content);
          this.ordinaryFrom.cover = res.info.task_cover_src;
          this.coverSrc = res.info.task_cover;
          if (res.info.task_file) {
            let list = [];
            let obj = {
              file_name: res.info.task_file.file_name,
              file_path: res.info.task_file.file_src
            }
            list.push(obj);
            this.ordinaryFrom.file = list;
          }

        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    // 编辑任务
    editTask(row) {
      if (row.task_id) {
          this.$httpteacher.axiosGetBy(this.$api.taskEdit, {'task_id': row.task_id}, res => {
            if (res.code === 200) {
              let resInfo = res.info;
              let timeDate = new Date(res.info.task_end_time);
              this.ordinaryFrom.cover = resInfo.task_cover_file;
              this.coverSrc = resInfo.task_cover;
              // let task_platform_id = resInfo.task_platform_id == 1 ? '抖音' : resInfo.task_platform_id == 2 ? '快手' : resInfo.task_platform_id == 3 ? '哔哩哔哩' : resInfo.task_platform_id == 4 ? '美拍' : resInfo.task_platform_id == 5 ? '西瓜视频' : resInfo.task_platform_id == 6 ? "火山" : '请选择';
              let task_platform_id = Number(resInfo.task_platform_id);
              if (resInfo.class_info.length) {
                this.checkedCities = [];
                this.checkedCitiesCopy = []
                resInfo.class_info.map(item => {
                  this.checkedCities.push(item.id)
                  this.checkedCitiesCopy.push(item.id)
                })
                this.dialogFormVisi()
              }
              if(row.task_type === 3){
                this.getTrainClassList();
                this.taskType = 2;
                this.trainingFrom.task_id = row.task_id;
                this.trainingFrom.trainingName = resInfo.task_title;
                this.trainingFrom.trainingTime = timeDate;
                this.trainingFrom.platform = task_platform_id;
                let list = [];
                list.push(resInfo.classification_parent_id);
                list.push(resInfo.classification_id);
                this.trainingCascaderValue = list;
                this.trainingFromClassChange(this.trainingCascaderValue);
                this.trainingFrom.project = resInfo.train_id;
                this.trainingFrom.taskclass = resInfo.task_type_id;
              } else if(row.task_type === 4){
                this.getScriptList();
                this.taskType = 3;
                this.scriptFrom.task_id = row.task_id;
                this.scriptFrom.taskName = resInfo.task_title;
                this.scriptFrom.scriptIntro = resInfo.task_content;
                this.scriptFrom.scriptTime = timeDate;
                this.scriptFrom.scriptPlatform = task_platform_id;
                this.scriptFrom.selectScript = resInfo.script_id;
                this.scriptFrom.taskclass = resInfo.task_type_id;
              } else {
                this.taskType = 0;
                this.getSystemTask();
                this.ordinaryFrom.id = row.task_id;
                let timeDate = new Date(res.info.task_end_time);
                this.ordinaryFrom.title = resInfo.task_title;
                this.editorText.setWEHtml(resInfo.task_content);
                this.ordinaryFrom.taskclass = resInfo.task_type_id;
                this.ordinaryFrom.time = timeDate;
                this.ordinaryFrom.file = resInfo.task_file;
                this.ordinaryFrom.fabu = task_platform_id;
                this.ordinaryFrom.file = [];
                if (resInfo.task_file.length) {
                  let obj = {
                    file_name: resInfo.task_file[0].file_name,
                    file_path: resInfo.task_file[0].file_src
                  }
                  this.ordinaryFrom.file.push(obj)
                }
              }

            } else {
              this.$message.warning(res.msg)
            }
          })
      }
    },
    // 获取实训系统项目分类
    getTrainClassList() {
      this.$httpteacher.axiosGet(this.$api.trainClass_list, res => {
        if (res.code === 200) {
          this.trainingFromClassList = res.data;
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    // 筛选系统项目
    trainingFromClassChange(val) {
      this.trainingFrom.project = '';
      if (val.length) {
        this.$httpteacher.axiosGetBy(this.$api.train, {train_class_id: val[val.length - 1]}, res => {
          if (res.code === 200) {
            this.trainingFromList = res.data.data;
          } else {
            this.$message.warning(res.msg)
          }
        })
      }
    },
    // 选择项目填充
    trainingProjectChange(item) {
      ;
      this.trainingFrom.trainingName = item.title;
      this.trainingFrom.id = item.id;
    },
    // 脚本选择
    scriptProjectChange(item) {
      this.scriptFrom.id = item.id;
      this.scriptFrom.taskName = item.name;
      this.scriptFrom.scriptIntro = item.script_introduction;
    },
    // 获取脚本列表
    getScriptList() {
      this.$httpteacher.axiosGetBy(this.$api.script, {status: 1}, res => {
        if (res.code === 200) {
          this.scriptFromList = res.data.data;
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    // 发布任务
    releaseBtn() {
      if (this.taskType === 0 || this.taskType === 1) {
        this.ordinaryFrom.describe = this.editorText.getWEHtml();
        this.$refs['ordinaryFrom'].validate((valid) => {
          if (valid) {
            if (this.ordinaryFrom.describe === '<p><br></p>' || this.ordinaryFrom.cover === '' || this.ordinaryFrom.classGrable === '') {
              this.$message.warning('必填项不能为空！')
            } else {
              let formatTime = getFormatDate(this.ordinaryFrom.time);
              let fromData = new FormData();
              // if (this.ordinaryFrom.file.length) {
                let fileStr = JSON.stringify(this.ordinaryFrom.file);
                fromData.append('file', fileStr);
              // }
              fromData.append('task_title', this.ordinaryFrom.title);
              fromData.append('task_content', this.ordinaryFrom.describe);
              fromData.append('task_cover', this.ordinaryFrom.cover);
              fromData.append('task_end_time', formatTime);
              fromData.append('task_type_id', this.ordinaryFrom.taskclass);
              fromData.append('task_platform_id', this.ordinaryFrom.fabu);
              fromData.append('class_id', this.ordinaryFrom.classGrable);
              fromData.append('task_type', 1);
              if (this.ordinaryFrom.id) {
                fromData.append('task_id', this.ordinaryFrom.id);
              }
              this.$httpteacher.axiosPost(this.$api.save, fromData, res => {
                if (res.code === 200) {
                  this.$message({
                    message: '发布成功！',
                    type: 'success',
                    duration: 1000,
                    onClose: () => {
                      this.$refs.ordinaryFrom.resetFields();
                      this.emptyForm()
                      this.$emit('selectTabStatus');
                    }
                  })
                  this.checkedCitiesCopy = []
                  this.ordinaryFrom.file = []
                } else {
                  this.$message.warning(res.msg)
                }
              })
            }
          } else {
            return false;
          }
        })
      } else if (this.taskType === 2) {
        this.$refs['trainingFrom'].validate((valid) => {
          if (valid) {
            if (this.ordinaryFrom.classGrable === '' || this.ordinaryFrom.cover === '') {
              this.$message.warning('必填项不能为空！')
            } else {
              let formatTime = getFormatDate(this.trainingFrom.trainingTime);
              let formData = new FormData();
              formData.append('task_title', this.trainingFrom.trainingName);
              formData.append('task_end_time', formatTime);
              formData.append('task_platform_id', this.trainingFrom.platform);
              formData.append('class_id', this.ordinaryFrom.classGrable);
              formData.append('task_cover', this.ordinaryFrom.cover);
              formData.append('train_id', this.trainingFrom.id);
              formData.append('task_type', 3);
              formData.append('task_type_id',this.trainingFrom.taskclass)
              if(this.trainingFrom.task_id){
                formData.append('task_id', this.trainingFrom.task_id);
              }
              this.$httpteacher.axiosPost(this.$api.save, formData, res => {
                if (res.code === 200) {
                  this.$message({
                    message: '发布成功！',
                    type: 'success',
                    duration: 1000,
                    onClose: () => {
                      this.trainingCascaderValue = '';
                      this.$refs.trainingFrom.resetFields();
                      this.emptyForm()
                      this.$emit('selectTabStatus');
                    }
                  })
                  this.checkedCitiesCopy = []
                } else {
                  this.$message.warning(res.msg)
                }
              })
            }
          } else {
            return false
          }
        })
      } else if (this.taskType === 3) {
        this.$refs['scriptFrom'].validate((valid) => {
          if (valid) {
            if (this.ordinaryFrom.classGrable === '' || this.ordinaryFrom.cover === '') {
              this.$message.warning('必填项不能为空！')
            } else {
              let formatTime = getFormatDate(this.scriptFrom.scriptTime);
              let formData = new FormData();
              formData.append('task_title', this.scriptFrom.taskName);
              formData.append('task_type', 4);
              formData.append('script_id', this.scriptFrom.id);
              formData.append('task_end_time', formatTime);
              formData.append('task_platform_id', this.scriptFrom.scriptPlatform)
              formData.append('class_id', this.ordinaryFrom.classGrable);
              formData.append('task_cover', this.ordinaryFrom.cover);
              formData.append('task_content', this.scriptFrom.scriptIntro);
              formData.append('task_type_id', this.scriptFrom.taskclass);
              if(this.scriptFrom.task_id){
                formData.append('task_id', this.scriptFrom.task_id);
              }
              this.$httpteacher.axiosPost(this.$api.save, formData, res => {
                if (res.code === 200) {
                  this.$message({
                    message: '发布成功！',
                    type: 'success',
                    duration: 1000,
                    onClose: () => {
                      this.$refs.scriptFrom.resetFields();
                      this.emptyForm()
                      this.$emit('selectTabStatus');
                    }
                  })
                  this.checkedCitiesCopy = []
                } else {
                  this.$message.warning(res.msg)
                }
              })
            }
          } else {
            return false;
          }
        })
      }
    },
    cancelBtn() {
      this.$refs.ordinaryFrom.resetFields();
      this.$refs.trainingFrom.resetFields();
      this.emptyForm();
      this.$emit('selectTabStatus');
    },
    // 清空表单
    emptyForm() {
      this.ordinaryFrom.id = '';
      this.ordinaryFrom.describe = '';
      this.editorText.setWEHtml('<p><br></p>');
      this.ordinaryFrom.cover = '';
      this.coverSrc = '';
      this.ordinaryFrom.classGrable = '';
      this.checkedCities = [];
      this.ordinaryFrom.file = [];
      this.trainingCascaderValue = '';
      this.scriptFrom.task_id = '';
      this.trainingFrom.task_id = '';
    }
  }
}
</script>

<style scoped lang="scss">
.addTask {
  padding: 30px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .addTask-header {
    display: flex;

    span {
      display: inline-block;
      padding: 9px 12px;
      cursor: pointer;
      color: #333333;
      font-size: 14px;
      border-radius: 2px;
    }

    .activeClass {
      background: #1E33E4;
      color: #fff;
    }
  }

  .form-box {
    margin-top: 40px;
  }

  .announce {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    .announce-btn {
      padding: 12px 0;
      width: 120px;

      &:nth-child(1) {
        color: #fff;
        background: #4D5AFF;
        border-color: #4D5AFF;

        &:focus,
        &:hover {
          color: #fff;
          border-color: #463bdc;
          background-color: #463bdc;
        }
      }
    }
  }
}

#article_content {
  z-index: 1;
  width: 50%;
  margin-left: 77px;
}

.imggeshi {
  position: absolute;
  left: 8px;
  top: 24px;
  color: #999999;
  font-size: 12px;
}

.upload-img {
  display: flex;

  .upload-btn {
    width: 148px;
    height: 148px;
    border: 1px dashed #c0ccda;
    box-sizing: border-box;
    background-color: #fbfdff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #1d33e3;
    cursor: pointer;

    .addimg {
      width: 22px;
      height: 22px;
      background: #1d33e3;
      color: #ffffff;
      border-radius: 50%;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
    }
  }

  .cover-look {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 400;
    margin-left: 20px;
    position: relative;

    img {
      max-width: 148px;
      max-height: 158px;
    }

    span {
      position: absolute;
      right: -10px;
      top: 0px;
      cursor: pointer;
    }
  }
}

.file-look {
  display: flex;
  padding-left: 70px;

  .look-item {
    position: relative;

    span {
      position: absolute;
      right: -12px;
      bottom: 8px;
      cursor: pointer;
    }
  }
}

::v-deep .taskObject .el-form-item__label {
  &:before {
    content: '*';
    color: rgb(245, 108, 108);
    margin-right: 4px;
  }
}
</style>