<template>
  <div class="taskIndex">
    <div class="taskIndexHeader">
      <p @click="selectTabClick('1')" :style="selectTab === 1 ? 'background:#fff;color: #0824ce;' : ''">任务列表</p>
      <p @click="selectTabClick('2')" :style="selectTab === 2 ? 'background:#fff;color: #0824ce;' : ''">创建任务</p>
    </div>
    <el-scrollbar class="taskIndex-content">
      <taskList @selectTabEdit = "selectTabEdit" ref="TaskListUpdate" v-show="selectTab === 1" />
      <addTask @selectTabStatus="selectTabStatus" ref="addTaskEdit" v-show="selectTab === 2" />
    </el-scrollbar>
  </div>
</template>

<script>
import taskList from './../../../components/TeacherModule/taskList';
import addTask from './../../../components/TeacherModule/addTask';
export default {
  name: "taskIndex",
  components:{
    taskList,
    addTask
  },
  data(){
    return {
      selectTab:1
    }
  },
  methods:{
    selectTabClick(num){
      this.selectTab = Number(num)
    },
    selectTabStatus(){
      this.selectTab = 1;
      this.$refs.TaskListUpdate.getAllClass()
    },
    selectTabEdit(id){
      this.selectTab = 2;
      this.$refs.addTaskEdit.editTask(id)
    }
  }
}
</script>

<style scoped lang="scss">
.taskIndex{
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  .taskIndexHeader{
    margin:0;
    text-align: center;
    width: 100%;
    display: flex;
    p{
      color: #666666;
      font-size: 16px;
      width: 162px;
      line-height: 53px;
      border-radius: 6px 6px 0 0;
      margin-bottom: 0;
      cursor: pointer;
    }
  }
  .taskIndex-content{
    display: flex;
    flex-direction: column;
    height: 1%;
    flex: 1;
    background: #fff;
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
}
</style>