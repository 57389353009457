<template>
  <div class="taskList">
    <div class="task_class">
      <div>
        <p>任务类型：</p>
        <span
            style="cursor: pointer"
            v-for="item in classIfl"
            @click="selectType(item.id)"
            :class="{activeClass : classValue === item.id}"
        >{{item.name}}</span>
      </div>
      <div>
        <p>任务筛选：</p>
        <span
            style="cursor: pointer"
            v-for="(item,index) in renwusx"
            @click="selectTask(index)"
            :class="{activeClass:renwusxValue == index}"
        >{{item}}</span>
      </div>
    </div>
    <div class="table-box">
      <el-table
          :data="taskList"
          class="college-table"
          style="width: 100%;margin-top: 28px"
          size="medium"
          :header-cell-style="{fontWeight: 'normal', color: '#666666', background: '#F5F5F5',fontSize: '14px',height:'46px'}"
          :cell-style="{fontSize: '14px',color: '#666666',height:'80px'}"
      >
        <el-table-column label="序号" width="140" align="center" class-name="student-name" type="index"></el-table-column>
        <el-table-column prop="task_cover" label align="right" class-name="student-name">
          <template slot-scope="scope">
            <img style="max-width:80px;max-height:37px;" :src="scope.row.task_cover" />
          </template>
        </el-table-column>

        <el-table-column prop="task_title" label="任务详情" align="left" class-name="student-name">
          <template slot-scope="scope">
            <div class="taskDetail">
              <span class="studentNameTitle text-overflow">{{scope.row.task_title}}</span>
              <span class="studentNameContent text-overflow-2">{{scope.row.task_type_name}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="参与人数" align="center" class-name="student-name">
          <template slot-scope="scope">
            <span class="student-name-title">{{scope.row.num}}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="task_state"
            label="任务状态"
            align="center"
            class-name="student-name"
        >
          <template slot-scope="scope">
            <span class="student-name-title">{{scope.row.task_state}}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="create_time"
            label="发布时间"
            align="center"
            class-name="student-name"
        >
          <template slot-scope="scope">
            <span class="student-name-title">{{scope.row.create_time}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" style="color: #4D5AFF" @click="toDetailView(scope.row)">查看详情</el-button>
            <el-button type="text" size="small" style="color: #4D5AFF" @click="editTask(scope.row)">编辑</el-button>
            <el-button type="text" size="small" style="color: #DD1D1D" @click="detaTeacher(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-pagination
        class="pages-center"
        :current-page="adminPages.currentPageNum"
        :page-size="adminPages.eachPageNum"
        layout="prev, pager, next, jumper"
        :total="adminPages.total"
        @current-change="adminCurrentChange"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: "taskList",
  data(){
    return {
      classIfl:[
        {
          name:'全部',
          id:0
        },
        {
          name:'普通任务',
          id:1
        },
        {
          name:'实训任务',
          id:3
        },
        {
          name:'脚本任务',
          id:4
        },
      ], //任务类型筛选
      classValue:0,
      renwusx: ["全部", "进行中", "已截至"], // 任务筛选
      renwusxValue:'',
      //分页
      adminPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0
      },
      taskList:[]
    }
  },
  mounted() {
    this.getAllClass();
  },
  methods:{
    // // 获取分类列表
    // getClassify() {
    //   let params = {};
    //   this.$httpteacher.axiosGetBy(this.$api.classification,params, (res)=>{
    //     if(res.code === 200){
    //       this.classIfl = res.data.data;
    //     }
    //   })
    // },
    // 选择任务类型
    selectType(id){
      this.classValue = id;
      this.getAllClass()
    },
    // 任务筛选
    selectTask(index){
      this.renwusxValue = index;
      this.getAllClass();
    },
    // 获取全部任务列表
    getAllClass() {
      let param = {
        page: this.adminPages.currentPageNum,
        limit: this.adminPages.eachPageNum //页面显示条数
      };
      if(this.classValue){
        param.task_type = this.classValue
      }
      if(this.renwusxValue){
        param.task_state = this.renwusxValue
      }
      this.$httpteacher.axiosGetBy(this.$api.taskList, param, res => {
        if (res.code == 200) {
          this.taskList = res.data.list;
          this.adminPages.total = res.data.total
        }
      });
    },
    // 查看
    toDetailView(row){
      this.$router.push({
        path:'/teacher/taskcenter/viewdetails',
        query:{id:row.task_id}
      })
    },
    // 编辑
    editTask(row){
      this.$emit('selectTabEdit',row)
    },
    // 删除
    detaTeacher(row) {
      let that =this;
      const id = row.task_id;
      let param = {
        task_id: id
      };
      this.$confirm("删除系统任务后不能恢复，确定要删除吗？", "删除任务", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        that.$httpteacher.axiosGetBy(that.$api.delTask, param, res => {
          if (res.code == 200) {
            that.$message({
              type: "success",
              message: "删除成功",
              duration:1000,
              onClose:()=>{
                that.getAllClass();
              }
            });
          } else {
            that.$message.warning(res.msg);
          }
        });
      });
    },
    //分页
    adminCurrentChange(val) {
      this.adminPages.currentPageNum = val;
      this.getAllClass();
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep.pages-center{
  padding: 30px 0;
}
.taskList{
  display: flex;
  flex-direction: column;
  height: 100%;
  .task_class{
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #333333;
    div{
      display: flex;
      text-align: center;
      p{
        margin-left: 33px;
      }
      span{
        margin-top: 8px;
        line-height: 32px;
        display: inline-block;
        height: 32px;
        padding: 0 20px;
      }
    }
  }
}
.activeClass {
  background: #4d5aff;
  color: #ffffff;
}
.taskDetail{
  display: flex;
  flex-direction: column;
  justify-content: center;
  .studentNameContent{
    color: #999999;
    font-size: 12px;
  }
  span{
    line-height: 20px;
  }
  ::v-deepp{
    line-height:1;
  }
}
.table-box{
  height: calc(100vh - 400px);
  overflow-y: auto;
}
</style>